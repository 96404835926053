import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// A custom theme for this app
const theme = responsiveFontSizes(createTheme({
    typography: {
        h1: {
            fontSize: '2.5rem',
        },
        h2: {
            fontSize: '2rem',
        },
        h3: {
            fontSize: '1.8rem',
        },
        h4: {
            fontSize: '1.6rem',
        },
        h5: {
            fontSize: '1.4rem',
        },
        h6: {
            fontSize: '1.2rem',
        },
        button: {
            textTransform: 'none',
        },
        fontFamily: [
            'Nunito Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#FF6B3D',
            // contrastText: '#fff'
        },
        secondary: {
            main: '#0D1545',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 2.8,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
}));


export default theme;